export const BOOKIES = {
    SPORTY_BET: 'sporty bet',
    BET_9JA: 'bet 9ja',
    "22_BET": '22bet',
    "1XBET": '1xbet',
    BET_KING: 'betking',
    PARI_PESA: 'paripesa',
}


export const bookieOptions = [
    {
        image: "",
        bookie: "1xbet:ng",
        name: "1xbet -Nigeria",
        bestCodeName: BOOKIES['1XBET']
    },

    {
        image: "",
        bookie: "22bet",
        name: "22bet",
        bestCodeName: BOOKIES['22_BET']
    },
    {
        image: "",
        bookie: "bet9ja",
        name: "bet9ja",
        bestCodeName: BOOKIES['BET_9JA']
    },
    {
        image: "",
        bookie: "betking:ng",
        name: "betking -Nigeria",
        bestCodeName: BOOKIES['BET_KING']
    },
    {
        image: "",
        bookie: "msport",
        name: "msport",
        bestCodeName: BOOKIES['MSPORT']
    },
    {
        image: "",
        bookie: "sportybet:ng",
        name: "sportybet -Nigeria",
        bestCodeName: BOOKIES['SPORTY_BET']
    },
];





export const bookiesDestination = (bookie, code) => {
    if (bookie === BOOKIES.SPORTY_BET) return `https://www.sportybet.com/ng/?shareCode=${code}&c=ng`
    if (bookie === BOOKIES.BET_9JA) return `https://sports.bet9ja.com/?bookedCode=${code}`
}

export const bookiesArray = () => {
    try {
        return Object.values(BOOKIES).sort()
    } catch (error) {
        console.log(error);
        return;
    }
}



export const directPlay = [BOOKIES.SPORTY_BET, BOOKIES.BET_9JA]