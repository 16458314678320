import { Route, Routes } from "react-router-dom";
import classes from './App.module.css'
import Header from "./components/header/header";
import Index from "./pages/index";
import { addGamePath, addTipstersPath, addVipGamePath, allUsersPath, changeAdPath, changePasswordPath, convertRoute, editTipstersPath, loginPath, profilePath, registerPath, resetPasswordPath, sendPushNotificationPath, senMailPath, subscribePath, ticketsPath, updateAccountInfoPath, verifyAccount, vipRoute } from "./constant/routes";
import Login from "./pages/login/login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShareModal from "./components/shareModal/shareModal";
import Vip from "./pages/vip/vip";
import Subscribe from "./pages/subscribe/subscribe";
import HeaderTwo from "./components/headerTwo/headerTwo";
import Profile from "./pages/profile/profile";
import PrivateRoute from "./routing/protectedRoute";
import UpdateAccountInformation from "./pages/accountInformation/accountInformation";
import ChangePassword from "./pages/changePassword/changepassword";
import ResetPassword from "./pages/resetPassword/resetPassword";
import AdminRoute from "./routing/adminRoute";
import SideNav from "./pages/admin/sideNav/sideNav";
import Regular from "./pages/admin/Game/regularGame";
import AddVIPGames from "./pages/admin/Game/vipGame";
import AddTipster from "./pages/admin/Game/tipsters";
import SendPushNotification from "./pages/admin/Game/sendPushNotification";
import SendMail from "./pages/admin/sendMail/sendMail";
import Tickets from "./pages/admin/tickets/tickets";
import Users from "./pages/admin/tickets/users";
import UploadBanner from "./pages/admin/Game/Banner";
import { VerifyAccount } from "./pages/verifyAccount/verifyAccount";
import { NavigationContainer } from "./components/navigationContainer/navigationContainer";
import Convert from "./pages/Convert/Convert";
import { GiHamburgerMenu } from "react-icons/gi";
import { useState } from "react";
import Register from "./pages/login/register";
import EditTipsters from "./pages/admin/Game/editTipsters";

function App() {
  const [isSideNavVisible, setSideNavVisible] = useState(false)

  const toggleSideNav = () => setSideNavVisible(prev => !prev)

  return (
    <div className={classes.overallContainer}>
      <NavigationContainer />
      <ShareModal />
      <Routes>
        <Route path="/" element={
          <div className={classes.container}>
            <Header />
            <div className={classes.subContainer}>
              <Index />
              <div className={classes.vipIndexContainer}>
                <Vip />
              </div>
            </div>
          </div>
        } />


        <Route path={vipRoute} element={
          <div className={classes.container}>
            <Header />
            <div className={classes.subContainer}>
              <Vip standAlonePage={true} />
            </div>
          </div>
        } />

        <Route path={convertRoute} element={
          <div className={classes.container}>
            <Header />
            <div className={classes.subContainer}>
              <Convert standAlonePage={true} />
            </div>
          </div>
        } />



        <Route path={loginPath} element={
          <div className={classes.container}>
            <Header />
            <Login />
          </div>
        } />

        <Route path={registerPath} element={
          <div className={classes.container}>
            <Header />
            <Register />
          </div>
        } />


        <Route path={verifyAccount} element={
          <div className={classes.container}>
            <VerifyAccount />
          </div>
        } />


        <Route path={subscribePath} element={
          <>
            <HeaderTwo title={'Subscribe'} color={'#F24401'} />
            <div className={classes.container}>
              <Subscribe />
            </div>
          </>
        } />

        <Route path={profilePath} element={
          <PrivateRoute>
            <HeaderTwo title={'Profile'} color={'#de374b'} />
            <div className={classes.container}>
              <Profile />
            </div>
          </PrivateRoute>
        } />


        <Route path={updateAccountInfoPath} element={
          <PrivateRoute>
            <HeaderTwo title={'Update Account Information'} color={'#de374b'} />
            <div className={classes.container}>
              <UpdateAccountInformation />
            </div>
          </PrivateRoute>
        } />



        <Route path={changePasswordPath} element={
          <PrivateRoute>
            <HeaderTwo title={'Change Password'} color={'#de374b'} />
            <div className={classes.container}>
              <ChangePassword />
            </div>
          </PrivateRoute>
        } />


        <Route path={resetPasswordPath} element={
          <>
            <HeaderTwo title={'Reset Password'} color={'#de374b'} />
            <div className={classes.container}>
              <ResetPassword />
            </div>
          </>
        } />



        <Route path={addGamePath} element={
          <AdminRoute>
            <div className={classes.container}>
              <Header />
              <div>
                <GiHamburgerMenu onClick={toggleSideNav} className={classes.hamburgerMenu} />
              </div>
              <div className={classes.subContainer}>
                <div className={`${classes.sideNav} ${isSideNavVisible ? classes.sideNavHidden : ''}`}>
                  <SideNav toggleSideNav={toggleSideNav} />
                </div>
                <Regular />
              </div>
            </div>
          </AdminRoute>
        } />


        <Route path={addVipGamePath} element={
          <AdminRoute>
            <div className={classes.container}>
              <Header />
              <div>
                <GiHamburgerMenu onClick={toggleSideNav} className={classes.hamburgerMenu} />
              </div>
              <div className={classes.subContainer}>
                <div className={`${classes.sideNav} ${isSideNavVisible ? classes.sideNavHidden : ''}`}>
                  <SideNav toggleSideNav={toggleSideNav} />
                </div>
                <AddVIPGames />
              </div>
            </div>
          </AdminRoute>
        } />


        <Route path={addTipstersPath} element={
          <AdminRoute>
            <div className={classes.container}>
              <Header />
              <div>
                <GiHamburgerMenu onClick={toggleSideNav} className={classes.hamburgerMenu} />
              </div>
              <div className={classes.subContainer}>
                <div className={`${classes.sideNav} ${isSideNavVisible ? classes.sideNavHidden : ''}`}>
                  <SideNav toggleSideNav={toggleSideNav} />
                </div>
                <AddTipster />
              </div>
            </div>
          </AdminRoute>
        } />



        <Route path={editTipstersPath} element={
          <AdminRoute>
            <div className={classes.container}>
              <Header />
              <div>
                <GiHamburgerMenu onClick={toggleSideNav} className={classes.hamburgerMenu} />
              </div>
              <div className={classes.subContainer}>
                <div className={`${classes.sideNav} ${isSideNavVisible ? classes.sideNavHidden : ''}`}>
                  <SideNav toggleSideNav={toggleSideNav} />
                </div>
                <EditTipsters />
              </div>
            </div>
          </AdminRoute>
        } />

        <Route path={allUsersPath} element={
          <AdminRoute>
            <div className={classes.container}>
              <Header />
              <div>
                <GiHamburgerMenu onClick={toggleSideNav} className={classes.hamburgerMenu} />
              </div>
              <div className={classes.subContainer}>
                <div className={`${classes.sideNav} ${isSideNavVisible ? classes.sideNavHidden : ''}`}>
                  <SideNav toggleSideNav={toggleSideNav} />
                </div>
                <Users />
              </div>
            </div>
          </AdminRoute>
        } />



        <Route path={changeAdPath} element={
          <AdminRoute>
            <div className={classes.container}>
              <Header />
              <div>
                <GiHamburgerMenu onClick={toggleSideNav} className={classes.hamburgerMenu} />
              </div>
              <div className={classes.subContainer}>
                <div className={`${classes.sideNav} ${isSideNavVisible ? classes.sideNavHidden : ''}`}>
                  <SideNav toggleSideNav={toggleSideNav} />
                </div>
                <UploadBanner />
              </div>
            </div>
          </AdminRoute>
        } />


        <Route path={sendPushNotificationPath} element={
          <AdminRoute>
            <div className={classes.container}>
              <Header />
              <div>
                <GiHamburgerMenu onClick={toggleSideNav} className={classes.hamburgerMenu} />
              </div>
              <div className={classes.subContainer}>
                <div className={`${classes.sideNav} ${isSideNavVisible ? classes.sideNavHidden : ''}`}>
                  <SideNav toggleSideNav={toggleSideNav} />
                </div>
                <SendPushNotification />
              </div>
            </div>
          </AdminRoute>
        } />


        <Route path={senMailPath} element={
          <AdminRoute>
            <div className={classes.container}>
              <Header />
              <div>
                <GiHamburgerMenu onClick={toggleSideNav} className={classes.hamburgerMenu} />
              </div>
              <div className={classes.subContainer}>
                <div className={`${classes.sideNav} ${isSideNavVisible ? classes.sideNavHidden : ''}`}>
                  <SideNav toggleSideNav={toggleSideNav} />
                </div>
                <SendMail />
              </div>
            </div>
          </AdminRoute>
        } />


        <Route path={ticketsPath} element={
          <AdminRoute>
            <div className={classes.container}>
              <Header />
              <div>
                <GiHamburgerMenu onClick={toggleSideNav} className={classes.hamburgerMenu} />
              </div>
              <div className={classes.subContainer}>
                <div className={`${classes.sideNav} ${isSideNavVisible ? classes.sideNavHidden : ''}`}>
                  <SideNav toggleSideNav={toggleSideNav} />
                </div>
                <Tickets />
              </div>
            </div>
          </AdminRoute>
        } />


        <Route path={allUsersPath} element={
          <AdminRoute>
            <div className={classes.container}>
              <Header />
              <div>
                <GiHamburgerMenu onClick={toggleSideNav} className={classes.hamburgerMenu} />
              </div>
              <div className={classes.subContainer}>
                <div className={`${classes.sideNav} ${isSideNavVisible ? classes.sideNavHidden : ''}`}>
                  <SideNav toggleSideNav={toggleSideNav} />
                </div>
                <Users />
              </div>
            </div>
          </AdminRoute>
        } />



      </Routes>
      <ToastContainer />


    </div>
  );
}

export default App;
