/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import classes from './login.module.css';
import { FiEye, FiEyeOff } from "react-icons/fi";
import { show } from '../../constant/toastOptions';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Loader } from '../../components/loader/loader';
import { useNavigate, useSearchParams } from 'react-router-dom';


const url = process.env.REACT_APP_BACKEND_URL;

export default function Register() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        referralCode: '',
    });
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const [searchParams] = useSearchParams();



    useEffect(() => {
        const code = searchParams.get('referral-code');
        setFormData((prev) => ({
            ...prev,
            referralCode: code || ''
        }))
    }, [])





    const handleRegister = async (e) => {
        e.preventDefault();

        const { firstName, lastName, email, password, confirmPassword, referralCode } = formData;

        if (!firstName || !lastName || !email || !password || !confirmPassword) {
            show('All fields are required!', 'error');
            return;
        }

        if (password !== confirmPassword) {
            show('Passwords do not match!', 'error');
            return;
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        setIsLoading(true);
        try {
            await axios.post(`${url}/auth/register`, JSON.stringify({
                first_name: firstName,
                last_name: lastName,
                email,
                password,
                referral_code: referralCode,
            }), config);
            show('Registration successful!', 'success');
            navigate('/login');
        } catch (error) {
            const errorMessage = error?.response?.data?.msg || 'Something went wrong';
            show(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={classes.container}>
            <form onSubmit={handleRegister} className={classes.subContainer}>
                <p className={classes.title}>Create your account</p>
                <input
                    type="text"
                    name="firstName"
                    placeholder="Enter your first name"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    className={classes.input}
                />
                <input
                    type="text"
                    name="lastName"
                    placeholder="Enter your last name"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    className={classes.input}
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className={classes.input}
                />

                <input
                    type="text"
                    name="referralCode"
                    placeholder="Enter referral code (optional)"
                    value={formData.referralCode}
                    onChange={handleInputChange}
                    className={classes.input}
                />

                <div className={classes.passwordContainer}>
                    <input
                        type={isPasswordVisible ? 'text' : 'password'}
                        name="password"
                        placeholder="Enter your password"
                        value={formData.password}
                        onChange={handleInputChange}
                        className={classes.passwordInput}
                    />
                    <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className={classes.eyeIcon}
                    >
                        {isPasswordVisible ? <FiEyeOff /> : <FiEye />}
                    </button>
                </div>
                <input
                    type={isPasswordVisible ? 'text' : 'password'}
                    name="confirmPassword"
                    placeholder="Confirm your password"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    className={classes.input}
                />
                <button type="submit" className={classes.button} disabled={isLoading}>
                    {isLoading ? <Loader /> : 'Register'}
                </button>
            </form>
            <ToastContainer />
        </div>
    );
}
