import React, { useContext } from 'react'
import UserContext from '../../context/User/userContext'
import { formatDateHeader } from '../../helpers/formatDateHeader'
import { format } from 'date-fns'
import classes from './profile.module.css'
import { FiShare, FiUser } from "react-icons/fi";
import { GoLock } from "react-icons/go";
import { FaAngleRight, FaRegCreditCard } from "react-icons/fa6";
import { VscBook } from "react-icons/vsc";
import { addGamePath, changePasswordPath, subscribePath, updateAccountInfoPath } from '../../constant/routes'
import { useNavigate } from 'react-router-dom'
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { show } from '../../constant/toastOptions'
import axios from 'axios'
import { FaRegCopy } from 'react-icons/fa'

const url = process.env.REACT_APP_BACKEND_URL;

export default function Profile() {
    const { user, setUser } = useContext(UserContext)
    const navigate = useNavigate()
    const { first_name, last_name, subscriptionExpiration } = user



    const handleShare = async (msg) => {


        if (navigator.share) {
            navigator
                .share({
                    title: `Check out best codes! You can also download on playstore and app store`,
                    text: `https://www.bestcodes.vip/register?referral-code=${user?.referralCode}`,
                })
                .then(() => {
                    show('Code shared!', 'success');
                })
                .catch((error) => {
                    show('Error sharing code!', 'error');
                });
        } else {
            alert("Sharing is not supported on this browser.");
        }
    };

    const accountInformation = [
        { icons: <FiUser className={classes.icon} />, title: 'account information', route: updateAccountInfoPath },
        { icons: <GoLock className={classes.icon} />, title: 'change password', route: changePasswordPath },
        { icons: <FaRegCreditCard className={classes.icon} />, title: 'subscribe', route: subscribePath },
    ]

    const general = [
        {
            icons: <FaRegCopy className={classes.icon} color="black" />, title: `copy referral code - ${user?.referralCode}`, function: () => {
                navigator.clipboard.writeText(user?.referralCode)
                .then(() => show('Referral code copied to clipboard!', 'success'))
                .catch(err => console.error('Failed to copy code: ', err));
            }
        },
        { icons: <FiShare className={classes.icon} />, title: 'share & invite', function: () => { handleShare() } },
        { icons: <VscBook className={classes.icon} color="black" />, title: 'terms & conditions', function: () => { } },
    ]

    const logOut = () => {
        localStorage.removeItem('tok')
        setUser(null)
        navigate('/')
    }

    console.log(subscriptionExpiration);

    return (
        <div className={classes.container}>

            <div className={classes.nameContainer}>
                <p className={classes.name}>{`${first_name} ${last_name}`}</p>

                {
                    subscriptionExpiration &&
                    <p className={classes.timeStamp}>Your subscription expires by {formatDateHeader(format(new Date(subscriptionExpiration), 'yyyy-MM-dd'))} </p>
                }
            </div>

            <div className={classes.allItemsGroupedContainer}>
                <p className={classes.heading}>Account Information</p>
                <div className={classes.groupedItems}>
                    {
                        accountInformation?.map((item, index) => (
                            <div onClick={() => { navigate(item?.route) }} className={classes.itemsContainer} key={index}>
                                {item.icons}
                                <p className={classes.itemTitle}>{item.title}</p>
                                <FaAngleRight className={classes.angleRight} />
                            </div>
                        ))
                    }
                </div>


                <p className={classes.heading}>General</p>
                <div className={classes.groupedItems}>
                    {
                        general?.map((item, index) => (
                            <div onClick={item.function} className={classes.itemsContainer} key={index}>
                                {item.icons}
                                <p className={classes.itemTitle}>{item.title}</p>
                                <FaAngleRight className={classes.angleRight} />
                            </div>
                        ))
                    }
                </div>

                <p className={classes.heading}>Admin</p>
                {
                    (user?.role === 'admin' || user?.role === 'super_admin') &&
                    <div onClick={() => { navigate(addGamePath) }} className={classes.itemsContainer} >
                        <MdOutlineAdminPanelSettings className={classes.icon} />
                        <p className={classes.itemTitle}>Admin</p>
                        <FaAngleRight className={classes.angleRight} />
                    </div>
                }

                <button onClick={logOut} className={classes.logOutBtn}>
                    Log Out
                </button>

            </div>

        </div>
    )
}
