export const loginPath = '/login'
export const registerPath = '/register'
export const subscribePath = '/subscribe'
export const profilePath = '/profile'
export const changePasswordPath = '/change-password'
export const updateAccountInfoPath = '/update-account-info'
export const resetPasswordPath = '/reset-password'


export const addGamePath = '/admin/add-game'
export const addVipGamePath = '/admin/vip/add-game'
export const addTipstersPath = '/admin/add-tipster'
export const editTipstersPath = '/admin/edit-tipster'
export const sendPushNotificationPath = '/admin/send-push-notification'
export const senMailPath = '/admin/send-mail'
export const ticketsPath = '/admin/tickets'
export const allUsersPath = '/admin/users'
export const changeAdPath = '/admin/change-ad'


export const verifyAccount = '/verify-account'
export const vipRoute = '/vip'
export const convertRoute = '/convert'