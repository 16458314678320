/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import classes from '../tickets/tickets.module.css';
import Skeleton from '../../../components/skeleton/skeleton';
import { MdDeleteForever, MdEdit } from "react-icons/md";
import Dropzone from 'react-dropzone';
import { Loader } from '../../../components/loader/loader';

const url = process.env.REACT_APP_BACKEND_URL;

export default function EditTipsters() {
    const [activeTab, setActiveTab] = useState('regular');
    const [punters, setPunters] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [otherData, setOtherData] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const [showEditModal, setEditModal] = useState(null)
    const [handlingUpdate, setHandlingUpdate] = useState(false)
    const [deleting, setDeleting] = useState('')


    const handleEdit = (details) => {
        setEditModal(details)
    }

    const getPunters = async () => {
        setIsLoading(true)
        try {
            const res = await axios.get(`${url}/punters`);
            const data = res.data.punters;
            setPunters(data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    };

    const handleDelete = async (id) => {
        setDeleting(id)
        const config = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('tok')}`
            }
        };

        try {
            await axios.delete(`${url}/punters/delete/${id}`, config);
            // Refresh the punters after deletion
            getPunters();
        } catch (error) {
            console.error('Error deleting ticket:', error);
        } finally {
            setDeleting('')
        }
    };

    const { hasNextPage, hasPreviousPage, page } = otherData;

    useEffect(() => {
        getPunters(1);
    }, [activeTab, searchQuery]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };


    const handleUpdate = async () => {
        setHandlingUpdate(true)
        const formData = new FormData();
        formData.append("_id", showEditModal?._id);
        formData.append("punter_name", showEditModal?.punter_name);

        if (showEditModal?.image?.file?.[0]) {
            formData.append("punter_image", showEditModal?.image?.file?.[0]);
        }

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                authorization: `Bearer ${localStorage.getItem("tok")}`,
            },
        };

        try {
            await axios.patch(`${url}/punters/edit`, formData, config);
            setEditModal(null); // Close modal
            getPunters(); // Refresh data
        } catch (error) {
            console.error("Error updating punter:", error);
        } finally {
            setHandlingUpdate(false)
        }
    };




    const setImageToStorage = (file) => {
        const fileURL = URL.createObjectURL(file[0]);
        setEditModal((prev) => ({
            ...prev,
            image: {
                url: fileURL,
                file: file,
            }
        }))

    };

    const handleInputChange = (e) => {

        const { value } = e.target
        setEditModal((prev) => ({
            ...prev,
            punter_name: value
        }))
    }

    return (
        <div className={classes.container}>
            {
                showEditModal &&
                <div className={classes.overlay}>
                    <div className={classes.overlayCard}>
                        <p className={classes.editTitle}>Edit {showEditModal?.punter_name}</p>
                        <Dropzone
                            onDrop={(acceptedFiles) => {
                                setImageToStorage(acceptedFiles);
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div
                                        style={
                                            showEditModal?.image?.url ? { backgroundImage: `url(${showEditModal?.image?.url})` } : {}
                                        }
                                        className={classes.image_container}
                                        {...getRootProps()}
                                    >
                                        <input {...getInputProps()} />
                                        <p className={classes.text_image_uploader}>
                                            Drag 'n' drop some files here, or click to select files
                                            (JPG/PNG, 5mb max.)
                                        </p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                        <input value={showEditModal?.punter_name} onChange={handleInputChange} className={classes.punter_name_input} type='text' />

                        <button disabled={handlingUpdate} onClick={handleUpdate} className={classes.save}>Sav{handlingUpdate ? 'ing...' : 'e'}</button>
                    </div>
                </div>
            }

            <div className={classes.subContainer}>

                {/* <div className={classes.inputContainer}>
                    <input
                        type="text"
                        placeholder="Search by code or owner..."
                        value={searchQuery}
                        onChange={handleSearch}
                        className={classes.input}
                    />
                </div> */}

                {isLoading ? (
                    <div className={classes.loaderContainer}>
                        <Skeleton width={'100%'} height={'100%'} />
                    </div>
                ) : (
                    <>
                        <table className={classes.table}>
                            <thead>
                                <tr>
                                    <th>S/N</th>
                                    <th>Name</th>
                                    <th>Image</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {punters.map((item, index) => (
                                    <tr key={item._id}>
                                        <td>{index + 1}</td>
                                        <td>{item.punter_name}</td>
                                        <td>View Image</td>
                                        <td>
                                            <button
                                                className={classes.buttonContainer}
                                                onClick={() => handleEdit({ _id: item?._id, punter_name: item.punter_name, image: { url: item.punter_image } })}
                                            >
                                                <MdEdit className={classes.deleteBtn} />
                                            </button>
                                        </td>
                                        <td>
                                            <button
                                                disabled={deleting}
                                                className={classes.buttonContainer}
                                                onClick={() => handleDelete(item._id)}
                                            >
                                                {
                                                    deleting === item?.id ?
                                                        <Loader />
                                                        :
                                                        <MdDeleteForever className={classes.deleteBtn} />
                                                }

                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className={classes.pagination}>
                            <button onClick={() => getPunters(parseInt(page) - 1)} disabled={!hasPreviousPage}>Previous</button>
                            <span>Page {page} </span>
                            <button onClick={() => getPunters(parseInt(page) + 1)} disabled={!hasNextPage}>Next</button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
